var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "navbar" },
    [
      _c("hamburger", {
        staticClass: "hamburger-container",
        attrs: { id: "hamburger-container", "is-active": _vm.sidebar.opened },
        on: { toggleClick: _vm.toggleSideBar },
      }),
      _vm.needTagsView
        ? _c("tags-view", { staticClass: "tags-container" })
        : _vm._e(),
      _c(
        "div",
        { staticClass: "right-menu" },
        [
          _vm.device !== "mobile"
            ? [
                _c("error-log", {
                  staticClass: "errLog-container right-menu-item hover-effect",
                }),
              ]
            : _vm._e(),
          _c(
            "el-dropdown",
            { staticClass: "avatar-container right-menu-item hover-effect" },
            [
              _c("div", { staticClass: "avatar-wrapper" }, [
                _c("span", {}, [_vm._v(_vm._s(_vm.name))]),
                _c("i", { staticClass: "el-icon-caret-bottom" }),
              ]),
              _c(
                "el-dropdown-menu",
                { attrs: { slot: "dropdown" }, slot: "dropdown" },
                [
                  _c(
                    "el-dropdown-item",
                    {
                      nativeOn: {
                        click: function ($event) {
                          return _vm.logout($event)
                        },
                      },
                    },
                    [_c("div", { staticClass: "f12" }, [_vm._v("退出")])]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }