var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "tagsView",
      staticClass: "tags-view-container",
      attrs: { id: "tags-view-container" },
    },
    [
      _c(
        "div",
        { staticClass: "tags-view-wrapper" },
        [
          _vm._l(_vm.visitedViews, function (tag, index) {
            return _c(
              "router-link",
              {
                key: tag.path,
                ref: "tag",
                refInFor: true,
                staticClass: "tags-view-item",
                class: _vm.isActive(tag) ? "active" : "",
                attrs: {
                  to: {
                    path: tag.path,
                    query: tag.query,
                    fullPath: tag.fullPath,
                  },
                  tag: "span",
                },
                nativeOn: {
                  mouseup: function ($event) {
                    if ("button" in $event && $event.button !== 1) {
                      return null
                    }
                    !_vm.isAffix(tag) ? _vm.closeSelectedTag(tag) : ""
                  },
                },
              },
              [
                _c("span", { staticClass: "item-left" }),
                _vm._v(" " + _vm._s(tag.title) + " "),
                _c("span", {
                  staticClass: "el-icon-close",
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      $event.stopPropagation()
                      return _vm.closeSelectedTag(tag)
                    },
                  },
                }),
                _c("span", { staticClass: "item-right" }),
              ]
            )
          }),
          _vm.hiddenViews.length > 0
            ? _c(
                "el-dropdown",
                { attrs: { placement: "bottom-start", trigger: "click" } },
                [
                  _c("i", { staticClass: "el-icon-more icon-more-tags" }),
                  _c(
                    "el-dropdown-menu",
                    {
                      staticClass: "tag-dropdown-menu",
                      attrs: { slot: "dropdown" },
                      slot: "dropdown",
                    },
                    _vm._l(_vm.hiddenViews, function (tag, index) {
                      return _c(
                        "router-link",
                        {
                          key: tag.path,
                          ref: "tag",
                          refInFor: true,
                          staticClass: "tags-view-item",
                          class: _vm.isActive(tag) ? "active" : "",
                          attrs: {
                            to: {
                              path: tag.path,
                              query: tag.query,
                              fullPath: tag.fullPath,
                            },
                            tag: "span",
                          },
                          nativeOn: {
                            mouseup: function ($event) {
                              if ("button" in $event && $event.button !== 1) {
                                return null
                              }
                              !_vm.isAffix(tag) ? _vm.closeSelectedTag(tag) : ""
                            },
                          },
                        },
                        [
                          _c(
                            "el-dropdown-item",
                            { staticClass: "tags-more-item" },
                            [
                              _vm._v(" " + _vm._s(tag.title) + " "),
                              !_vm.isAffix(tag)
                                ? _c("span", {
                                    staticClass: "el-icon-close icon-del",
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        $event.stopPropagation()
                                        return _vm.closeSelectedTag(tag)
                                      },
                                    },
                                  })
                                : _vm._e(),
                            ]
                          ),
                        ],
                        1
                      )
                    }),
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }