import router from './router'
import store from './store'
import { Message } from 'element-ui'
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style
import { getToken } from '@/utils/auth' // get token from cookie
import getPageTitle from '@/utils/get-page-title'
import { constantRoutes,asyncRoutes,commonRouters } from "./router/index";
import Layout from '@/layout'
import AppMain from '@/layout/components/AppMain'

//权限路由  动态路由
function hasPermission(permissions, route) {
  if (route.meta && route.meta.url) {
    // console.log("权限==>{}", permissions);
    return permissions.some(permission => {
      // console.log(route.meta.url, permission.url);
      if (route.meta.url == permission.url) {
        route.id = permission.id;
        return true;
      } else if (permission.children && permission.children.length > 0) {
        return hasPermission(permission.children, route);
      }
      return false;
    });
  } else {
    return true
  }
}

//动态路由  权限路由
// export function filterAsyncRoutes(routes, permissions) {
//   const res = []

//   routes.forEach(route => {
//     const tmp = { ...route }
//     // console.log(tmp)
//     if (hasPermission(permissions, tmp)) {
//       if (tmp.children) {
//         tmp.children = filterAsyncRoutes(tmp.children, permissions)
//       }
//       res.push(tmp)
//     }
//   })

//   return res
// }
export function filterAsyncRoutes(asyncRouterMap){
  // console.log(asyncRouterMap)
  const arr = asyncRouterMap.filter(route => {
    if(route.isFirst){
      let a = JSON.parse(JSON.stringify(route.component))
      // console.log(route)
      route.component = Layout
      route.redirect=route.path
      route.children=[
        {
          path:route.path,
          component: loadPageByRoutes(a),
          name: route.name,
          meta: {title: route.title, icon: route.icon}
        }
      ]
    }else{
      route.meta= {title: route.title, icon: route.icon}
      if (route.component) {
        if (route.component === 'Layout') {
          route.component = Layout
        } else if(route.component === 'AppMain') {
          route.component = AppMain
        } else {
          // route.component = loadView(route.component)
          route.component = loadPageByRoutes(route.component)
        }
      }
      if (route.children && route.children.length && route.children[0].name) {
        route.children = filterAsyncRoutes(route.children)
      }
    }
    return true
  })
  // console.log(arr)
  return arr
}


export function loadPageByRoutes(str) { // views文件夹下的Home组件，传入的格式为 'Home'
  return function (resolve) {
    // console.log(str)
    require([`@/${str}.vue`], resolve)
  }
}

NProgress.configure({ showSpinner: false }) // NProgress Configuration

const whiteList = ['/login', '/auth-redirect', '/401', '/404'] // no redirect whitelist

router.beforeEach(async(to, from, next) => {
  // start progress bar
  NProgress.start()

  // set page title
  document.title = getPageTitle(to.meta.title)

  // determine whether the user has logged in
  const hasToken = getToken()

  if (hasToken) {
    if (to.path === '/login') {
      // if is logged in, redirect to the home page
      next({ path: '/' })
      NProgress.done() // hack: https://github.com/PanJiaChen/vue-element-admin/pull/2939
    } else {
      // determine whether the user has obtained his permission roles through getInfo

      // TODO 页面权限判断

      // const hasRoles = store.getters.roles && store.getters.roles.length > 0
      const hasGetUserInfo = store.getters.name
      if (hasGetUserInfo) {
        next()
      } else {
        try {
          // get user info
          // note: roles must be a object array! such as: ['admin'] or ,['developer','editor']
          // TODO 从服务端获取用户权限列表
          await store.dispatch('user/getInfo')
          let permissions=store.getters.permissions;
          // console.log("权限列表==>", permissions);
          let accessedRoutes = filterAsyncRoutes(permissions);
          // console.log("accessedRoutes==>", accessedRoutes)
          let allRouter=constantRoutes.concat(accessedRoutes)
          // console.log("allRouter", allRouter);

          await store.dispatch('permission/setRoutes', accessedRoutes);

          router.options.routes = allRouter;
          router.addRoutes(accessedRoutes);
          next({ ...to, replace: true })
        } catch (error) {
          // remove token and go to login page to re-login
          await store.dispatch('user/resetToken')
          Message.error(error || 'Has Error')
          next(`/login?redirect=${to.path}`)
          NProgress.done()
        }
      }
    }
  } else {
    /* has no token*/

    if (whiteList.indexOf(to.path) !== -1) {
      // in the free login whitelist, go directly
      next()
    } else {
      // other pages that do not have permission to access are redirected to the login page.
      next(`/login?redirect=${to.path}`)
      NProgress.done()
    }
  }
})


router.afterEach(() => {
  // finish progress bar
  NProgress.done()
})
