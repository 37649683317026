import { getToken } from "@/utils/auth"
import { baseURL } from '@/settings'
import request from '@/utils/request'

export function uploadAction() {
  // return baseURL + '/tbCustomerUpload/upload?token='+ getToken()
  return baseURL + '/openapi/file/upload'
}




export function uploadFile(data) {
  return request({
    url: baseURL + '/openapi/file/upload',
    method: 'post',
    data
  })
}
