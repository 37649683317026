import request from '@/utils/request'

// 登陆
export function login(data) {
  return request({
    url: '/auth/login',//
    method: 'post',
    data
  })
}

// 当前登录人信息
export function currentUser() {
  return request({
    url: '/auth/currentUser',
    method: 'post',
  })
}

// 退出
export function logout() {
  return request({
    // url: '/vue-element-admin/user/logout',
    url: '/auth/logout',
    method: 'post'
  })
}