import { login, logout, currentUser } from '@/api/system/auth'
import { getToken, setToken, removeToken } from '@/utils/auth'
import router, { resetRouter } from '@/router'
import {menusConventToTree} from '@/utils/permission'

// const state = {
//   token: getToken(),
//   name: '',
//   avatar: '',
//   introduction: '',
//   roles: [],
//   permissions: []
// }

const getDefaultState = () => {
  return {
    token: getToken(),
    introduction: '',
    name: '',
    id: '',
    roles: [],
    permissions: [],
    btnPermissions: [],
    userRootOrganizations:[],
    newsAudit:false,
  }
}

const state = getDefaultState();

const mutations = {
  RESET_STATE: (state) => {
    Object.assign(state, getDefaultState())
  },
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_INTRODUCTION: (state, introduction) => {
    state.introduction = introduction
  },
  SET_NEWS_AUDIT: (state, name) => {
    state.newsAudit = true
  },
  SET_NAME: (state, name) => {
    // console.log('SET_NAME-----',name)
    state.name = name
  },
  SET_USER_ID: (state, id) => {
    state.id = id
  },

  SET_ROLES: (state, roles) => {
    state.roles = roles
  },
  SET_PERMISSIONS: (state, permissions) => {
    state.permissions = permissions
  },
  SET_BTNPERMISSIONS: (state, btnPermissions) => {
    state.btnPermissions = btnPermissions
  },
}

const actions = {
  // user login
  login({ commit }, userInfo) {
    const { username, password } = userInfo
    return new Promise((resolve, reject) => {
      login({ username: username.trim(), password: password }).then(response => {
        const { data } = response
        console.log("login res==>"+ JSON.stringify(data));
        commit('SET_TOKEN', data.token)
        setToken(data.token)
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },

  // get user info
  getInfo({ commit, state }) {
    return new Promise((resolve, reject) => {
        currentUser().then(response => {
          // console.log("用户信息==>", response.data);
          const { data } = response;
          if (!data) {
            return reject('Verification failed, please Login again.')
          }
          localStorage.setItem('version',true)
          // console.log(data)
          //获取路由
          // let permissionsTemp = data.userMenus;
          // let permissions = menusConventToTree(permissionsTemp);
          // console.log("permissions==>", permissions);
          // console.log(data.userMenus)
          let permissions = data.userMenus;
          let btnPermissions = [];
          if(permissions){
            for(let i = 0 ; i < permissions.length ; i++){
              if(permissions[i].type==0){
                fun(permissions[i],permissions[i].children)
              }else{
                // let btnArr=[]
                permissions[i].isFirst=true
                // permissions[i].children.forEach(item=>{
                //   btnArr.push(item.meta.title)
                // })
                // btnPermissions.push({name:permissions[i].name,btnArr})
                permissions[i].children=[]
              }

              if(permissions[i].path =='/information'){
                for(let a = 0;a<permissions[i].children.length;a++){
                  if(permissions[i].children[a].path == '/InfoAuditing'){
                    commit('SET_NEWS_AUDIT', true)
                  }
                }
              }
            }
          }

          // console.log(btnPermissions)

          function fun(Farr,arr) {
            // console.log(Farr,arr)
            if(arr == null){
              return;
            }
            for(let i=0;i<arr.length;i++){
              if(arr[i].type==1){
                // let btnArr=[]
                // for(let a=0;a<arr[i].children.length;a++){
                //   btnArr.push(arr[i].children[a].meta.title)
                // }
                // btnPermissions.push({name:arr[i].name,btnArr})
                arr[i].children=[]
              }else{
                fun(arr[i],arr[i].children)
              }
            }
          }

          commit('SET_PERMISSIONS', permissions?permissions:[]);
          // commit('SET_BTNPERMISSIONS', btnPermissions);
          commit('SET_NAME', data.realname)
          commit('SET_USER_ID', data.id)
          // commit('SET_USERROOTORGANIZATIONS',data.userRootOrganizations)
          // commit('SET_AVATAR', avatar)
          resolve(data);

          // let data = {
          //   roles: ['admin'],
          //   name: response.data.realname,
          //   avatar: '',
          //   introduction: 'zs introduction'
          // }
          // const { roles, name, avatar, introduction } = data
          // commit('SET_ROLES', roles)
          // commit('SET_NAME', response.data.realname)
          // commit('SET_AVATAR', avatar)
          // commit('SET_INTRODUCTION', introduction)
          // resolve(data)
        }).catch(error => {
          reject(error)
        })



      // getInfo(state.token).then(response => {
      //   const { data } = response

      //   if (!data) {
      //     reject('Verification failed, please Login again.')
      //   }

      //   const { roles, name, avatar, introduction } = data

      //   // roles must be a non-empty array
      //   if (!roles || roles.length <= 0) {
      //     reject('getInfo: roles must be a non-null array!')
      //   }

      //   commit('SET_ROLES', roles)
      //   commit('SET_NAME', name)
      //   commit('SET_AVATAR', avatar)
      //   commit('SET_INTRODUCTION', introduction)
      //   resolve(data)
      // }).catch(error => {
      //   reject(error)
      // })
    })
  },

  // user logout
  logout({ commit, state, dispatch }) {
    return new Promise((resolve, reject) => {
      logout(state.token).then(() => {
        // commit('SET_TOKEN', '')
        // commit('SET_ROLES', [])
        removeToken()
        resetRouter()
        commit('SET_NAME', '');
        commit('SET_PERMISSIONS', []);
        localStorage.setItem('version',true)
        // reset visited views and cached views
        // to fixed https://github.com/PanJiaChen/vue-element-admin/issues/2485
        dispatch('tagsView/delAllViews', null, { root: true })

        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },

  // remove token
  resetToken({ commit }) {
    return new Promise(resolve => {
      commit('SET_TOKEN', '')
      commit('SET_ROLES', [])
      commit('SET_NAME', '');
      commit('SET_PERMISSIONS', []);
      removeToken()
      resolve()
    })
  },

  // dynamically modify permissions
  async changeRoles({ commit, dispatch }, role) {
    const token = role + '-token'

    commit('SET_TOKEN', token)
    setToken(token)

    const { roles } = await dispatch('getInfo')

    resetRouter()

    // generate accessible routes map based on roles
    const accessRoutes = await dispatch('permission/generateRoutes', roles, { root: true })
    // dynamically add accessible routes
    router.addRoutes(accessRoutes)

    // reset visited views and cached views
    dispatch('tagsView/delAllViews', null, { root: true })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
