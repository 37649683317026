var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    {
      staticClass: "app-main",
      style: _vm.key == "/dashboard" ? "background:#eeeeee" : "",
    },
    [
      _c(
        "transition",
        { attrs: { name: "fade-transform", mode: "out-in" } },
        [
          _c(
            "keep-alive",
            [_vm.$route.meta.keepAlive ? _c("router-view") : _vm._e()],
            1
          ),
        ],
        1
      ),
      _c(
        "transition",
        { attrs: { name: "fade-transform", mode: "out-in" } },
        [!_vm.$route.meta.keepAlive ? _c("router-view") : _vm._e()],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }